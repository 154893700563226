<template>
  <div>
    <Header />
    <div style="margin-top: 20px">
      <div class="topTitle">
        会议列表
      </div>
      <div class="topTabs">
        <div v-if="meetShow">
          <el-button type="primary" @click="newMeet">新建会议</el-button>
        </div>
        <div style="margin-left: auto;margin-right: 0">
          <el-tabs class="tabClas" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="进行中" name="0"></el-tab-pane>
            <el-tab-pane label="已结束" name="1"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div style="width: 40%;height: 1px;border-top: 1.5px solid #ccc;margin: 0 auto;margin-bottom: 10px"></div>
      <div class="outBlock" v-for="(item,index) in listArr" :key="index">
        <div class="firBlo" v-if="item.picture" @click="meetClick(item)">
          <el-image :src=url+item.picture></el-image>
        </div>
        <div class="firBlo" v-else @click="meetClick(item)">
          <el-image :src="require('@/assets/nopic.jpg')"></el-image>
        </div>
        <div class="botBlo" style="position: relative;">
          <div class="titBlo" @click="meetClick(item)">{{ item.name }}</div>
          <div class="dataBlo" @click="meetClick(item)">
            {{ item.conferenceTime }}
          </div>
          <div v-if="meetShow" style="display: flex;position: absolute;bottom: 12px;right: 0;">
            <el-button size="mini" type="primary" @click="editMeet(item)"><i class="el-icon-edit">编辑</i></el-button>
            <el-button size="mini" type="danger" @click="delMeet(item)"><i class="el-icon-delete-solid">删除</i></el-button>
          </div>
        </div>
      </div>
    </div>


    <el-pagination @current-change="handleCurrentChange" background :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
    </el-pagination>

    <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="410px" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
        <el-form-item label="会议名称：" prop="name">
          <el-input v-model="AddDateModel.name" placeholder="请填写会议名称"></el-input>
        </el-form-item>

        <el-form-item label="会议地址：" prop="name">
          <el-input v-model="AddDateModel.address" placeholder="请填写会议地址"></el-input>
        </el-form-item>

        <el-form-item label="办会时间：" prop="name">
          <el-input v-model="AddDateModel.conferenceTime" placeholder="请填写办会时间"></el-input>
        </el-form-item>
        <el-form-item label="会议封面：" prop="picture">
          <el-upload class="avatar-uploader" ref="otherLicense"
                     action
                     :auto-upload="false"
                     :on-remove="handleRemove"
                     :file-list="pict"
                     :class="{ hide: hideUploadBtn }"
                     :limit="1"
                     :on-change="otherSectionFile"
                     list-type="picture-card" multiple>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="会议状态：" prop="status" v-if="editMeetingStatus">
          <el-select v-model="AddDateModel.status" placeholder="请选择会议状态">
            <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">提交</el-button>
          <el-button v-else type="info" :loading="addDepClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import _qs from "qs";
import Vue from "vue";
import Header from "@/components/Header/index.vue";
import sunburstSeries from "echarts/src/chart/sunburst/SunburstSeries";
export default {
    data() {
        return {
          meetId:'',
          addName: "新建会议",
          statusOptions: [
            {
              id: "0",
              name: "进行中",
            },
            {
              id: "1",
              name: "结束",
            }
          ],
          editMeetingStatus:false,
          activeName:'0',
          roleId:window.sessionStorage.getItem("roleId"),
          addDepClick: true,
          addDepClickKing: false,
          listArr:[],
          // 当前页数
          pageNum: 1,
          // 每页显示条数
          pageSize: 10,
          // 总数
          total: 1,
          url:'',
          pict:[],
          AddDateModel: {
            name: "",
            status:"",
            picture:"",
            address:"",
            conferenceTime:""
          },
          AddDateRules: {
            name: [
              { required: true, message: "请填写会议名称", trigger: "blur" },
              { required: true, message: "封面", trigger: "blur" },
              { required: true, message: "请填写地点", trigger: "blur" },
              { required: true, message: "请填写办会时间", trigger: "blur" },
            ],
            // picture:[
            //   {
            //     required: true,
            //     validator: validateImage,
            //     trigger: "blur",
            //   },
            // ]
          },
          AdddialogVisible: false,
          hideUploadBtn:false,
          meetShow:false,
          sponsorList: [''],
          assistList: [''],
          organizerList: [''],
          guidanceList: [''],
          mediaList: [''],
        };
    },
    created() {
      if(this.roleId.includes('2')){
        this.meetShow=true
      }
      this.pageList()
    },
    components: {
      Header
    },
    methods: {
      sunburstSeries,
      handleClick() {
        this.pageNum = 1;
        this.pageList()
      },
      handleRemove(file,fileList) {
        this.hideUploadBtn = fileList.length >= 1;
      },      otherSectionFile(file,fileList) {
        // console.log(file,fileList)
        this.hideUploadBtn = fileList.length >= 1;
        const typeArr = [
          "image/png",
          "image/gif",
          "image/jpeg",
          "image/jpg",
        ];
        const isJPG = typeArr.indexOf(file.raw.type) !== -1;
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error("只能是图片!");
          this.$refs.upload.clearFiles();
          this.otherFiles = null;
          return;
        }
        if (!isLt20M) {
          this.$message.error("上传图片大小不能超过 20MB!");
          this.$refs.upload.clearFiles();
          this.otherFiles = null;
          return;
        }
        this.otherFiles = file.raw;
        var formData = new FormData();
        formData.append("conferencePosters", this.otherFiles);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          methods: "post",
        };
        this.$axios.uploadPosters(formData, config).then((res) => {
          // console.log(res)
          // console.log(res.data.msg)
          if(res.data.code==200){
            this.AddDateModel.picture=res.data.msg
          }
        });
      },
      parmen() {
        if (this.addName == "新建会议") {
          this.addMeeting();
        } else if (this.addName == "编辑会议") {
          this.editMeeting();
        }
      },
      async delMeet(val) {
        const confirmResult = await this.$confirm("确认删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirmResult !== "confirm") {
          return this.$message.info("已经取消删除");
        }
        let data = _qs.stringify({
          id:val.id
        });
        let { data: res } = await this.$axios.deleteMeet(data);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.pageList();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      },
      addMeeting(){
        this.$refs.AddDateRef.validate(async(valid) => {
          if (valid) {
            this.addDepClick = false;
            this.addDepClickKing = true;
            let data = _qs.stringify({
              name: this.AddDateModel.name,
              picture: this.AddDateModel.picture,
              address: this.AddDateModel.address,
              conferenceTime: this.AddDateModel.conferenceTime
            });
            let res = await this.$axios.saveMeeting(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
              this.$router.push("/login");
            } else if (res.status == 200) {
              this.AdddialogVisibleClose();
              this.pageList();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          }
        });
      },
      editMeeting(){
        this.$refs.AddDateRef.validate(async(valid) => {
          if (valid) {
            this.addDepClick = false;
            this.addDepClickKing = true;
            let data = _qs.stringify({
              id:this.meetId,
              name: this.AddDateModel.name,
              status: this.AddDateModel.status,
              picture: this.AddDateModel.picture,
              address: this.AddDateModel.address,
              conferenceTime: this.AddDateModel.conferenceTime
            });
            let res = await this.$axios.editMeeting(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
              this.$router.push("/login");
            } else if (res.status == 200) {
              this.AdddialogVisibleClose();
              this.pageList();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          }
        });
      },
      newMeet(){
        this.AdddialogVisible = true;
        this.editMeetingStatus = false;
      },
      editMeet(val){
        this.AdddialogVisible = true;
        this.editMeetingStatus = true;
        this.addName = "编辑会议";
        this.meetId = val.id;
        this.AddDateModel = {
          name: val.name,
          status:val.status+'',
          picture: val.picture,
          address: val.address,
          conferenceTime: val.conferenceTime,
        }
        this.pict=[{url:Vue.prototype.GLOBAL+val.picture}]
        this.AddDateModel.picture=this.picture
        this.AdddialogVisible = true;
        this.hideUploadBtn = this.pict.length >= 1;


      },
      // 点击添加科室的时候取消正则
      AdddialogVisibleClose() {
        this.addName = "新建会议";
        this.name = "";
        this.status = "";
        this.picture = "";
        this.address = "";
        this.conferenceTime = "";
        this.AdddialogVisible = false;
        this.$refs.AddDateRef.resetFields();
        this.AddDateModel = {
          name: ""
        };
        this.hideUploadBtn=false
        this.pict=[]
      },
      meetClick(val){
        window.sessionStorage.setItem("meetingId", val.id);
        this.$router.push("/venue");
      },
      async pageList(){
        let data = _qs.stringify({
          page:this.pageNum,//页
          size:this.pageSize,//条
          searchParams:'',// 名称
          status:this.activeName,// 0 进行中 1 已结束
        });
        let { data: res } = await this.$axios.meetingPageList(
            data
        );
        // console.log(res.data);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.listArr=res.data.records
          this.total=res.data.total
          this.url=Vue.prototype.GLOBAL
        }else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.pageList();
      },
    },
};
</script>

<style lang="less" scoped>

.outBlock:hover{
  cursor: pointer;
}

.topTabs{
  width: 40%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topTitle{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

  .outBlock{
    width: 38%;
    line-height: 35px;
    background: white;
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 0 16px #ccc;
    //border-radius: 20px 0 20px 20px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .el-pagination {
    text-align: center;
  }
  .conferenceTitle{
    font-weight: 700;
  }
  .conferenceTitle:hover{
    color:red;
  }

  /deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
      button {
        width: 150px;
      }
    }
  }

  /deep/ .el-input__inner{
    width: 200px;
  }

  .hide {
    /deep/.el-upload--picture-card {
      display: none !important;
    }
  }

  /deep/ .tabClas .el-tabs__item{
    padding: 0 10px!important;
  }

/deep/ .firBlo .el-image img{
  width: 250px;
  height: 140px;
  object-fit: cover!important;
  //border-radius: 20px;
}
/deep/ .firBlo .el-image__error{
  width: 250px;
  height: 140px;
}

.outBlock{
  width: 38%;
  background: white;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-shadow: 0 0 16px #ccc;
  //border-radius: 20px 0 20px 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  /deep/ .firBlo .el-image img{
    width: 250px;
    height: 140px;
    object-fit: cover!important;
    //border-radius: 20px;
  }
  /deep/ .firBlo .el-image__error{
    width: 250px;
    height: 140px;
  }
  .botBlo{
    width: 100%;
    margin-left: 10px;
    .titBlo{
      font-size: 20px;
      font-weight: bold;
    }
    .dataBlo{
      color: #6e6767;
      margin-top: 5px;
    }
  }
}
</style>
